.popup {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  /* background-color: rgba(178, 221, 243, 0.5); */
  background-color: rgba(177, 177, 177, 1);

  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;

}

.pop-active {
  opacity: 1;
}

.popup-content {
  background-color: white;
  /* padding: 20px; */
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.2);
  max-width: 80%;
  text-align: center;
  position: relative;
  color: black;
}

h1 {
  font-size: 1.3em;
  margin-bottom: 10px;
  line-height: 1em;
  color: #666;
}

h1:hover {
  color: #111
}

.popup-content img {
  max-width: 80%;
  height: 60%;
  border-radius: 50%;
  margin-bottom: 1em;
}

.popup-content img:hover {
  transform: scale(1.1);
}


p {
  font-size: 1em;
}

p:hover {
  transform: scale(1.03);
}

.popup_vegan {
  background-color: green;
  max-width: 7vw;
  transform: rotate(-5deg);
  margin-bottom: 1vh;
}

.description {
  width: 80%;
  padding: 10px 20px;
}

.popup_contains {

  padding: 10px;
}

.popup-key {
  font-weight: bold;
}

.description {
  padding-bottom: 2vh;
  width: 70vw;
  margin: 0 auto;
}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 2em;
  cursor: pointer;
  opacity: 0.5;
}


.close-button:hover {
  color: red;
}

@media only screen and (max-width:600px) {
  .popup {
    overflow: scroll;
  }

  .close-button {
    position: absolute;
    top: 0;
    right: 0;
    font-size: 2em;
    cursor: pointer;
    opacity: 0.5;
  }

  .popup-content img {
    min-width: 100%;
    border-radius: 0;

  }


  .popup_vegan {
    max-width: 15vw;
  }
}