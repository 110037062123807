body{
  color:black;
}
a,p{
  padding:0;
  margin:0;
}
.location-container {
  display: flex;
  flex-direction: row;
}
.staticMap img {
  display: block;
  width: 60vw;
  height: 100vh;
  margin: 0;
}

.searchcontainer {
  /* padding: 17vh 3vw; */
  padding-top: 17vh;
  width: 40vw;
  background-color: #f2f2f2;
  overflow-y: auto;
  height: 100vh;
  text-align: left;
  background:linear-gradient(40deg,rgb(65, 58, 58),rgb(44, 35, 35),rgb(71, 53, 53),rgb(74, 65, 65),rgb(184, 148, 148));
}
.searchcontainer h1 {
  font-weight: 600;
  line-height: 1em;
  font-size: 1.8em;
}
.searchcontainer input {
  width: 20vw;
  height: 8vh;
  font-size: 1.2em;
  border: 1px solid #ccc;
  padding: 0.5em;
  border-radius: 3px;
}

.searchcontainer button {
  height: 8vh;
  width: 4vw;
  background-color: #e5007e;
  color: #fff;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s;
}

.searchcontainer button:hover {
  background-color: #f13ea0;
}
.searchsticky{
  display: flex;
  flex-direction: row;
  gap:2px;
}

.searchsticky{
  text-align: center;
  position: sticky;
  top:0vh;
  margin-bottom: 2vh;

}

.result-restaurant>.resultstorelist{
  margin-left: 2vw;
  
}
.noresult {
  padding-top: 2vw;
  color: #777;
  font-size: 1em;
}

.storecard {
  background-color: #fff;
  margin: 2vh;
  line-height: 1.3;
  border: 1px solid #eee;
  border-radius: 5px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  line-height: 2em;
  padding:2vw
}

.storecard-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* padding: 0 1em; */
}
.storecard-header h1{
  font-weight: bold;
  font-size: 1.3em !important;
  margin: 0;
}

.openclose {
  font-weight: bold;
  font-size: 1.3em;
  color: #e5007e;
}

.storecard .direction_button, .storecard .deliveroo {
  width: 90%;
  height: 10vh;
  font-size: 1.2em;
  font-weight: bold;
  margin: 2vh ;
  color: #fff;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s;
}
.storecard img{
  width: 10vw;
}

.storecard .direction_button {
  background-color: #fff
}

.storecard .deliveroo {
  background-color: #fff
}

a.no-underline{
  text-decoration: none;
}

@media (max-width: 600px) {
  a,p{
    padding:0;
    margin:0;
  }
  .location-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
  }
  .staticMap img {
    display: none;
  }

  .searchcontainer {
    padding-top: 13vh;
    width: 95vw;
    height: 80vh;
    text-align: left;
 
  }
  .searchcontainer h1 {
    font-size: 1.3em;
    line-height: 1.2;
    text-align: left;
  }

  .searchcontainer input {
    width: 70%;
    height: 15vw;
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 10px;
  }

  .searchcontainer button {
    width: 25%;
    height: 15vw;
    background-color: #e5007e;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
    font-size: 1em;
  }

  .searchcontainer button:hover {
    background-color: #f13ea0;
  }
  .storecard-header{
    padding-left: 0;
  }
  .result-restaurant {
    overflow: visible;
    /* margin-bottom: 20vh; */
  }
.storecard-header h1{
  font-weight: bold;
  font-size: 1.2em !important;
  margin: 0;
}
.storecard .direction_button, .storecard .deliveroo {
  width: 90%;
  height: 5vh;
  /* font-size: 1.2em; */
  font-weight: bold;
  margin: 2vh;
  color: #fff;
  border: none;
  cursor: pointer;
  /* transition: background-color 0.3s; */
  background-color: #e5007e
 
}
.deliveroo img{
padding:0;
width: 20vw;

}

  .mobileimage img {
    width: 95vw;
    height: 30vh;
    margin: 2 auto;
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  footer {
    text-align: center;
    padding: 10px;
  }
}
