
*{
  box-sizing: border-box;
}
body{
  margin:0;
  padding: 0;
  font-family: 'Fira Sans', sans-serif;
}



h1, h2, h3, h4, h5, h6 {
  font-family: 'Martel Sans', sans-serif;
}


.menu{
  background-color: black;
  color: white;
}



/* .category{
  text-decoration-line: underline;
} */



