
.middleimage img{
  width: 50px;
  filter: grayscale(70%);
}
body{
  background-color:white;
  color:black;
}
.order{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* border: 2px solid red; */
  padding-top:25vh;
  padding-bottom: 10vh;
    background: linear-gradient(90deg,#e5007e,#35b8b2);
  }
  .image-container {
    display: flex;
  }
  .image1{
    width: 10vw;
    margin-right: 1vw;
  }
  .image2{
    max-width: 25vw;
  }
  .image1:hover,.image2:hover{
   transform: scale(1.2);
  
  }
.linkContainerStyle{
  flex:1;
  width: 40vw;
  height: 20vh;
  padding:2em;
  margin-top: 3vh;
  background-color:lightblue;
  line-height: 2vh;
  text-align: center;
  cursor: pointer;
  /* background: linear-gradient(90deg,#e5007e,#9beaef); */
}
.linkContainerStyle p{
  line-height: 1em;
}
.linkContainerStyle:hover{
  /* transform: scale(1.1); */
  transform: translateX(5px);
  animation: shake 0.5s ease-in-out infinite;
  /* background-color: aqua; */
}
@keyframes shake{
  0%,100%{
    transform: translateX(0);
  }
  25%{
    transform: translateX(-5px);
  }
  50%{
    transform: translateX(5px);
  }
  75%{
    transform: translateX(-5px);
  }
}

footer{
 
  color:black;
}
@media (max-width:600px) {
  .order{
    padding-top: 15vh;
  }
  .image1{
    width: 20vw;
    margin-right: 1vw;
  }
  .image2{
    max-width: 50vw;
  }
  .linkContainerStyle{
    padding: 2vw;
    width:70%;

  }
 
}