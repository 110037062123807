.navbar {
  display: flex;
  justify-content: space-between; 
  align-items: center; 
  background-color: #333;
  color: #fff;
  padding:0;
  width: 100%;
  font-size:2em;
  position:fixed;
  z-index: 1;
  height: 15vh;
  /* margin-top: 0; */
  top:0;
  left:0;
 }
 .navbar button{
  font-size:1em;
  background-color: #e5007e;
  border-radius: 10%;
  color: white;
 }
 .navbar a:hover{
  text-decoration: underline;
 }
.navbar button:hover{
  background-color: #ef66ad
}
.logo img {
  max-height: 15vh; 
  max-width: 20vw;
  margin: 0;
  padding: 0;
}
.active{
  font-weight: bolder;
}
.tabs{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  
}
.tabs a {
  text-decoration: none;
  color: #fff;
  margin-right: 5vw; 
}

/* Style the last tab differently */
.tabs a:last-child {
   margin-right: 1vw; 
   /* width: 20vw; */
}
@media screen and (max-width:600px){
  .navbar{
    font-size: 1em;
  }
  .tabs a{
    text-decoration: none;
    color:#fff;
  }
  .tabs a:last-child {
    margin-right: 3vw; 
    /* width: 20vw; */
    /* width: 15vw; */
 }


}
